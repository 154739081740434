import React from 'react';
import { Button, ButtonGroup, Col, Container, Form, Row } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ConnectionSelector from './Helpers/ConnectionSelector';
import Loader from '../Common/Loader';
import { reducer, sendRequest } from '../utils';
import { UNKNOWN_ERROR_MESSAGE } from '../constants';

const DuplicateLabel = () => {
  const navigate = useNavigate();
  const { labelId } = useParams();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {},
      message: '',
      error: '',
      isLoading: false,
      isError: false,
    },
  );

  const handleNameChange = (e) => {
    dispatch({
      type: 'FETCH_SUCCESS',
      payload: { ...state.data, name: e.target.value },
    });
  };

  const handleConnectionChange = (newConnection) => {
    dispatch({
      type: 'FETCH_SUCCESS',
      payload: { ...state.data, connection_id: newConnection && newConnection._id },
    });
  };

  const onCreateNewLabelClick = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`labels/${labelId}/duplicate`, 'POST', { name: state.data.name, connection_id: state.data.connection_id })
      .then((response) => {
        if (response.status_code === 200) {
          // Redirect to new label
          navigate(`/${response.data._id}`);
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
      });
  };

  const handleCancelClick = () => {
    navigate('/');
  };

  React.useEffect(() => {
    // Load original label to get name and connection
    sendRequest(`labels/${labelId}`, 'GET')
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: {
              ...state.data,
              name: `${response.data.name} (copy)`,
              connection_id: response.data.connection_id,
            },
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
      });
  }, []);

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="8">
          <h4 className="mb-4">Duplicate Label</h4>
          <p className="lead">
            On this step Designer will validate if it possible to create a new label using
            new connection and create new label. If you get any errors, please check
            your connection or Odoo settings and try again.
          </p>
          <Form>
            {state.error && (
              <div className="alert alert-danger" role="alert">
                {state.error}
              </div>
            )}

            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>New Label Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                defaultValue={state.data.name}
                onChange={handleNameChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Connection</Form.Label>
              <ConnectionSelector
                defaultValue={state.data.connection_id}
                onChange={handleConnectionChange}
              />
            </Form.Group>

            <Row>
              <Col>
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={onCreateNewLabelClick}
                    disabled={!state.data.name || !state.data.connection_id}
                  >
                    Create New Label
                  </Button>
                  <Button as={NavLink} to="/" variant="outline-primary" onClick={handleCancelClick}>Cancel</Button>
                </ButtonGroup>
              </Col>

              {
                state.isLoading && (
                <Col>
                  <Loader align="right" />
                </Col>
                )
              }
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default DuplicateLabel;
