import React from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'react-bootstrap';

const Loader = ({ align = 'center' }) => {
  let alignmentClass = 'justify-content-center';
  if (align === 'right') {
    alignmentClass = 'justify-content-end';
  } else if (align === 'left') {
    alignmentClass = 'justify-content-start';
  }

  return (
    <div className={`d-flex ${alignmentClass}`}>
      <Spinner animation="border" />
    </div>
  );
};

Loader.propTypes = {
  align: PropTypes.string,
};

Loader.defaultProps = {
  align: 'center',
};

export default Loader;
