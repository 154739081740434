import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-solid-svg-icons';
import { useDragAndDrop, useFontFaceObserver, useKeyPress } from '../../hooks';
import CanvasWrapper from './canvas_wrapper';
import ControlPanel from './ControlPanel/ControlPanel';
import { sendRequest } from '../../utils';
import { SERVER_DOWN_ERROR } from '../../constants';
import EditorButtons from './EditorButtons';
import { getPreviewFromLabelary } from './utils';

const ALLOWED_IMAGE_TYPES = ['image/png', 'image/jpeg'];

const processDroppedImages = (files, onSuccess, onError) => {
  const imageFiles = ([...files]).filter(
    (f) => ALLOWED_IMAGE_TYPES.includes(f.type),
  );

  if (!imageFiles.length) {
    onError('Only PNG and JPEG images are allowed');
  }

  imageFiles.forEach((file) => {
    if (file.size > 1 * 1024 * 1024) {
      onError('Image is too big. Maximum allowed size is 1 MB');
      return;
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    sendRequest('images/convert', 'POST', formData, true, false)
      .then((resp) => {
        if (resp.status_code !== 200) {
          onError(resp.message || SERVER_DOWN_ERROR);
        }
        onSuccess(resp.data.content);
      })
      .catch((error) => {
        onError(error.message || SERVER_DOWN_ERROR);
      });
  }, this);
};

const LabelEditor = ({ widthInch, heightInch, dpi, model, defaultContent, connectionId, onChange }) => {
  const isFontsLoaded = useFontFaceObserver([
    { family: 'Roboto Condensed', weight: '700' },
    { family: 'Roboto Mono' },
  ]);
  const [withGrid, setWithGrid] = useState(false);

  const wrapperRef = useRef();
  const canvasWrapperRef = useRef(null);
  const canvasRef = useRef(null);

  // Canvas keypress
  const backspacePressed = useKeyPress('Backspace');
  const deletePressed = useKeyPress('Delete');

  // Drag & drop
  const { dragOver, setDragOver, onDragOver, onDragLeave, fileDropError, setFileDropError } = useDragAndDrop();

  // Control panel
  const [controlPanelSettings, setControlPanelSettings] = useState({ element: null, show: false });
  // Save control panel state to pass to event handlers
  const controlPanelSettingsRef = useRef(controlPanelSettings);

  const handleCanvasChange = (content) => {
    onChange(content);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    setDragOver(false);
    setFileDropError(null);

    const dt = e.dataTransfer;

    if (dt.types.indexOf('Files') !== -1) {
      processDroppedImages(
        dt.files,
        (content) => wrapperRef.current.addElement({ className: 'Image', attrs: { url: content } }),
        (error) => setFileDropError(error),
      );
    }
  };

  const handleContextmenuEvent = React.useCallback((element) => {
    let selectedElement = controlPanelSettingsRef.current.element;
    let showControlPanel = false;

    // If element is in group - select group
    if (element && element.getParent().getType() === 'Group') {
      element = element.getParent();
    }

    // Toggle control panel on next clicks for the same object
    if (element) {
      if (element === selectedElement) {
        showControlPanel = !controlPanelSettingsRef.current.show;
      } else {
        selectedElement = element;
        showControlPanel = true;
      }
    } else {
      // No object selected
      selectedElement = null;
      showControlPanel = false;
    }

    // Update control panel state
    const newSettings = {
      element: selectedElement,
      show: showControlPanel,
    };
    setControlPanelSettings(newSettings);
    controlPanelSettingsRef.current = newSettings;
  }, [controlPanelSettings.show, controlPanelSettings.element]);

  const handleLeftClickEvent = React.useCallback(() => {
    const newSettings = {
      element: null,
      show: false,
    };
    setControlPanelSettings(newSettings);
    controlPanelSettingsRef.current = newSettings;
  }, [controlPanelSettings.show, controlPanelSettings.element]);

  const handleContentReady = (elements) => {
    elements.forEach(async (element) => {
      if (element.getType() === 'Group') {
        if (element.attrs.type === 'NestedLabel') {
          const labelId = element.attrs.data.label_id;

          const response = await sendRequest(`labels/${labelId}`, 'GET');
          if (response.status_code === 200) {
            const label = response.data;
            const onImageLoad = (base64) => {
              const newWidth = Math.round(label.width * label.dpi);
              const newHeight = Math.round(label.height * label.dpi);

              const nestedImage = element.findOne('Image');

              if (nestedImage) {
                nestedImage.destroy();
              }

              // Replace nested image with new one
              CanvasWrapper.createImage(
                { url: base64, width: newWidth, height: newHeight, draggable: false, x: 0, y: 0 },
                (image) => {
                  element.add(image);
                  element.getStage().batchDraw();
                },
              );

              // Update rect size
              const rect = element.findOne('Rect');
              rect.width(label.width * label.dpi);
              rect.height(label.height * label.dpi);
            };

            const onImageError = (error) => {
              alert(error);
            };

            getPreviewFromLabelary(
              label.zpl, label.dpi, label.width, label.height,
              onImageLoad, onImageError,
            );
          } else {
            // eslint-disable-next-line no-console
            console.log(response);
            alert('Error loading label: ', response.message);
          }
        }
      }
    });
  };

  // Initialize or update canvas. Attach events
  useEffect(() => {
    if (!canvasRef.current || !isFontsLoaded) return;

    if (wrapperRef.current) {
      // Just resize the canvas
      wrapperRef.current.setDimensions(
        Math.round(widthInch * dpi),
        Math.round(heightInch * dpi),
        dpi,
      );

      // Update grid
      if (withGrid) {
        wrapperRef.current.addGrid();
      }
      return;
    }

    const wrapper = new CanvasWrapper({
      canvasEl: canvasRef.current,
      dpi,
      width: Math.round(widthInch * dpi),
      height: Math.round(heightInch * dpi),
      content: defaultContent,
      onChange: handleCanvasChange,
      onLeftClickEvent: handleLeftClickEvent,
      onContextmenuEvent: handleContextmenuEvent,
      onContentReady: handleContentReady,
      // handleObjectEditing, // Change visibility of control panel
    });
    wrapperRef.current = wrapper;
  }, [widthInch, heightInch, dpi, isFontsLoaded]);

  // Update grid
  useEffect(() => {
    if (wrapperRef.current) {
      if (withGrid) {
        wrapperRef.current.addGrid();
      } else {
        wrapperRef.current.removeGrid();
      }
    }
  }, [withGrid]);

  // Remove objects from the canvas when user clicks on Backspace or Delete buttons
  useEffect(() => {
    if (backspacePressed || deletePressed) {
      // Check if canvas is in focus
      if (!canvasWrapperRef.current.contains(document.activeElement)) return;

      const activeElement = wrapperRef.current.getActiveElement();

      if (activeElement) {
        wrapperRef.current.remove(activeElement);
        // Hide control panel
        setControlPanelSettings({ object: null, show: false });
      }
    }
  }, [backspacePressed, deletePressed]);

  return (
    <div
      className="zld-canvas-container"
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={handleDrop}
    >
      {
        isFontsLoaded && (
          <>
            {fileDropError && (
              <Alert variant="danger">
                {fileDropError}
              </Alert>
            )}

            <div className="zld-canvas-wrapper" ref={canvasWrapperRef}>
              <div id="zld-canvas" className="border d-inline-block" ref={canvasRef} onContextMenu={() => false} />
            </div>

            <small>
              <input type="checkbox" id="zld-show-grid" name="zld-show-grid" onClick={(e) => setWithGrid(e.target.checked)} />
              <label htmlFor="zld-show-grid" className="mx-1">Show grid (each square has a side of approximately 1 mm on printed label)</label>
            </small>

            {/* Buttons */}
            <EditorButtons model={model} connectionId={connectionId} wrapperRef={wrapperRef} />

            {/* Control Panel */}
            <ControlPanel show={controlPanelSettings.show} element={controlPanelSettings.element} />

            {/* Drag & Drop */}
            <div className={`zld-drag-over ${!dragOver ? 'd-none' : ''}`}>
              <div className="zld-drag-over-content">
                <div>
                  <FontAwesomeIcon icon={faFileImage} size="5x" />
                </div>
                <div>
                  <h2>Drop your images here</h2>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  );
};

LabelEditor.propTypes = {
  widthInch: PropTypes.number.isRequired,
  heightInch: PropTypes.number.isRequired,
  dpi: PropTypes.number.isRequired,
  model: PropTypes.string.isRequired,
  defaultContent: PropTypes.arrayOf(PropTypes.object),
  connectionId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

LabelEditor.defaultProps = {
  defaultContent: [],
};

export default LabelEditor;
