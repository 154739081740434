import React from 'react';
import { Button, ButtonGroup, Col, Container, Form, Row } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';

import { reducer, sendRequest } from '../utils';
import ModelSelect from './Helpers/ModelSelect';
// import NoOdooConnection from './Helpers/NoOdooConnection';
import { UNKNOWN_ERROR_MESSAGE } from '../constants';
import Loader from '../Common/Loader';
import ConnectionSelector from './Helpers/ConnectionSelector';
import ConnectionStatus from './Helpers/ConnectionStatus';

const CreateLabel = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {
        name: '',
        model: { value: '', label: '' },
        orientation: 'normal',
        width: 5,
        height: 2.5,
        dpi: 152,
        encoding: 28,
        content: [],
      },
      message: '',
      error: '',
      isLoading: false,
      isError: false,
    },
  );
  const navigate = useNavigate();
  const [connection, setConnection] = React.useState(null);

  const handleFieldChange = (field, value) => {
    dispatch({
      type: 'FETCH_SUCCESS',
      payload: { ...state.data, [field]: value },
    });
  };

  const handleConnectionChange = (connection) => {
    setConnection(connection);
  };

  const handleCancelClick = (e) => {
    e.preventDefault();

    // Ask for confirmation
    if (window.confirm('Are you sure you want to cancel? All unsaved changes will be lost.')) {
      navigate('/');
    }
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    // Validate form
    if (!state.data.name || !state.data.model.value || !state.data.width || !state.data.height || !state.data.dpi) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please fill all required fields' });
      return;
    }

    const opts = {
      ...state.data,
      connection_id: connection._id,
    };

    // Save label in database
    sendRequest('labels', 'POST', opts)
      .then((response) => {
        if (response.status_code === 200) {
          // Redirect to created label
          navigate(`/${response.data._id}`);
        } else {
          dispatch({
            type: 'FETCH_FAILURE',
            error: response.message,
          });
        }
      }).catch(() => {
        dispatch({
          type: 'FETCH_FAILURE',
          error: UNKNOWN_ERROR_MESSAGE,
        });
      });
  };

  if (state.isLoading) {
    return <Loader />;
  }

  if (!connection) {
    return (
      <>
        <h2 className="mb-4">Create Label</h2>
        <p className="lead mb-4">Select Odoo connection to use for a new label</p>
        <ConnectionSelector onChange={handleConnectionChange} />
      </>
    );
  }

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="12">
          <h2 className="mb-4 d-flex justify-content-between align-items-center">
            Create Label

            {
              connection && <ConnectionStatus connectionId={connection._id} />
            }
          </h2>
          <Form onSubmit={(e) => { e.preventDefault(); }}>
            {state.error && (
              <div className="alert alert-danger" role="alert">
                {state.error}
              </div>
            )}

            {state.message && (
              <div className="alert alert-success" role="alert">
                {state.message}
              </div>
            )}

            <div className="d-flex align-items-center mb-4">
              <ButtonGroup className="me-4">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!(state.data.name && state.data.model.value && state.data.width && state.data.height && state.data.dpi)}
                  onClick={handleSaveClick}
                >
                  Save Label
                </Button>
                <Button as={NavLink} to="/" variant="outline-primary" type="submit" onClick={handleCancelClick}>Cancel</Button>
              </ButtonGroup>
            </div>

            <Row>
              <Form.Group as={Col} className="mb-3" controlId="name">
                <Form.Label>Connection</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={`${connection.name} (URL: ${connection.url} | DB: ${connection.database})`}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter label name"
                  defaultValue={state.data.name}
                  onChange={(e) => handleFieldChange('name', e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} className="mb-3" controlId="model">
                <ModelSelect
                  connectionId={connection._id}
                  value={state.data.model.value}
                  onChange={(value) => handleFieldChange('model', value)}
                />
              </Form.Group>

              <Form.Group as={Col} className="mb-3" controlId="orientation">
                <Form.Label>Orientation</Form.Label>
                <Form.Select
                  id="orientation"
                  name="orientation"
                  onChange={(e) => handleFieldChange('orientation', e.target.value)}
                >
                  <option value="normal">Normal</option>
                  <option value="inverted">Inverted</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} className="mb-3" controlId="name">
                <Form.Label>Width, inches</Form.Label>
                <Form.Control
                  type="number"
                  name="width"
                  placeholder="Enter width"
                  min="0.1"
                  step="0.1"
                  defaultValue={state.data.width}
                  onChange={(e) => handleFieldChange('width', Number(e.target.value))}
                />
              </Form.Group>

              <Form.Group as={Col} className="mb-3" controlId="model">
                <Form.Label>Height, inches</Form.Label>
                <Form.Control
                  type="number"
                  name="height"
                  placeholder="Enter height"
                  min="0.1"
                  step="0.1"
                  defaultValue={state.data.height}
                  onChange={(e) => handleFieldChange('height', Number(e.target.value))}
                />
              </Form.Group>

              <Form.Group as={Col} className="mb-3" controlId="dpi">
                <Form.Label>DPI</Form.Label>
                <Form.Select
                  id="dpi"
                  name="dpi"
                  defaultValue={state.data.dpi}
                  onChange={(e) => handleFieldChange('dpi', Number(e.target.value))}
                  required
                >
                  <option value="152">6dpmm (152 dpi)</option>
                  <option value="203">8dpmm (203 dpi)</option>
                  <option value="300">12dpmm (300 dpi)</option>
                  <option value="600">24dpmm (600 dpi)</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} className="mb-3" controlId="encoding">
                <Form.Label>Encoding</Form.Label>
                <Form.Select
                  id="encoding"
                  name="encoding"
                  defaultValue={state.data.encoding}
                  onChange={(e) => handleFieldChange('encoding', Number(e.target.value))}
                  required
                >
                  <option value="0">U.S.A. 1 Character Set</option>
                  <option value="1">U.S.A. 2 Character Set</option>
                  <option value="2">U.K. Character Set</option>
                  <option value="3">Holland Character Set</option>
                  <option value="4">Denmark/Norway Character Set</option>
                  <option value="5">Sweden/Finland Character Set</option>
                  <option value="6">Germany Character Set</option>
                  <option value="7">France 1 Character Set</option>
                  <option value="8">France 2 Character Set</option>
                  <option value="9">Italy Character Set</option>
                  <option value="10">Spain Character Set</option>
                  <option value="11">Miscellaneous Character Set</option>
                  <option value="12">Japan (ASCII with Yen symbol) Character Set</option>
                  <option value="14">Double Byte Asian Encodings</option>
                  <option value="28">Unicode (UTF-8) Character Set (recommended)</option>
                </Form.Select>
              </Form.Group>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateLabel;
