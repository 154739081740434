import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loader from '../../Common/Loader';
import { UNKNOWN_ERROR_MESSAGE } from '../../constants';
import { reducer, sendRequest } from '../../utils';

const ConnectionSelector = ({ defaultValue, onChange }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: true, isError: false,
    },
  );
  const [selectedValue, setSelectionValue] = React.useState(null);

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('connections', 'GET')
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
      });
  }, []);

  React.useEffect(() => {
    // Update selected value if default value is provided
    if (defaultValue && state.data.length) {
      const connection = state.data.find((connection) => connection._id === defaultValue);
      setSelectionValue(connection);
    }
  }, [defaultValue, state.data]);

  const handleConnectionChange = (e) => {
    const connectionId = e.target.value;
    const connection = state.data.find((connection) => connection._id === connectionId);

    setSelectionValue(connection);
    dispatch({ type: 'FETCH_SUCCESS' });

    if (connection) {
      dispatch({ type: 'FETCH_INIT' });

      // Validate connection
      sendRequest(`connections/${connectionId}/status`, 'GET')
        .then((response) => {
          if (response.status_code === 200) {
            onChange(connection);
            dispatch({ type: 'FETCH_SUCCESS' });
          } else {
            // Reset selection
            onChange(null);
            dispatch({ type: 'FETCH_FAILURE', error: response.message });
          }
        })
        .catch((error) => {
        // eslint-disable-next-line no-console
          console.log(error);
          dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
        });
    }
  };

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <>
      {
      !state.data.length ? (
        <Alert variant="warning">
          <Alert.Heading>
            No connections found
          </Alert.Heading>
          <p>
            Please, create a new connection to use for a new label.
          </p>
          <Button as={NavLink} to="/connections/new" variant="outline-primary" className="ml-2">Create connection</Button>
        </Alert>
      )
        : (
          <>
            <select
              className="form-select"
              aria-label="Default select example"
              value={selectedValue ? selectedValue._id : ''}
              onChange={handleConnectionChange}
            >
              <option value="">Select connection</option>
              {state.data.map((connection) => (
                <option key={connection._id} value={connection._id}>
                  {`${connection.name} (URL: ${connection.url}, DB: ${connection.database})`}
                </option>
              ))}
            </select>

            {state.isError && (
              <div className="alert alert-danger mt-2" role="alert">
                {state.error}
              </div>
            )}

          </>
        )
      }
    </>
  );
};

ConnectionSelector.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ConnectionSelector.defaultProps = {
  defaultValue: null,
};

export default ConnectionSelector;
