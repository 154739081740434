import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import './App.css';

import Login from './Auth/Login';
import PrivateRoute from './Auth/PrivateRoute';
import Menu from './Common/Menu';
import Register from './Auth/Register';
import LabelsList from './Labels/List';
import { useAuth } from './utils';
import CreateLabel from './Labels/Create';
import Connection from './Odoo/Connection';
import EditLabel from './Labels/Edit';
import NotFound from './Common/404';
import ErrorFallback from './Common/ErrorFallback';
import HowTo from './Odoo/HowTo';
import Connections from './Odoo/Connections';
import ForgotPassword from './Auth/ForgotPassword';
import ConfirmAccount from './Auth/ConfirmAccount';
import ResendConfirmation from './Auth/ResendConfirmation';
import ResetPassword from './Auth/ResetPassword';
import DuplicateLabel from './Labels/Duplicate';
// import DragAndDropTest from './Labels/Helpers/DragAndDropTest';

function App() {
  const [logged] = useAuth();

  return (
    <BrowserRouter>
      <Menu logged={logged} />
      <div className="container mt-4 mb-4">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/confirm-account/:token" element={<ConfirmAccount />} />
            <Route exact path="/resend-confirmation" element={<ResendConfirmation />} />
            <Route exact path="/reset-password/:token" element={<ResetPassword />} />

            <Route element={<PrivateRoute logged={logged} />}>
              <Route exact path="/" element={<LabelsList />} />
              <Route exact path="/new" element={<CreateLabel />} />
              <Route exact path="/duplicate/:labelId" element={<DuplicateLabel />} />
              <Route exact path="/:labelId" element={<EditLabel />} />
              <Route exact path="/connections" element={<Connections />} />
              <Route exact path="/connections/new" element={<Connection />} />
              <Route exact path="/connections/:connectionId" element={<Connection />} />
              <Route exact path="/odoo/how-to" element={<HowTo />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
}

export default App;
