import React from 'react';
import { Alert, Button, ButtonGroup, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { UNKNOWN_ERROR_MESSAGE } from '../constants';
import { reducer, sendRequest } from '../utils';

const validateOdooConnection = (creds) => creds.url.trim() && creds.database.trim() && creds.api_key.trim();

const validateURL = (url) => {
  try {
    /* eslint-disable no-new */
    new URL(url.trim());
    return true;
  } catch (err) {
    return false;
  }
};

const Connection = () => {
  const navigate = useNavigate();
  const { connectionId } = useParams();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: { name: '', url: '', database: '', api_key: '' },
      message: '',
      error: '',
      isLoading: true,
      isError: false,
    },
  );

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    dispatch({ type: 'FETCH_SUCCESS', payload: { ...state.data, [name]: value } });
  };

  const handleURLChange = (e) => {
    const url = e.target.value;

    if (validateURL(url)) {
      const newURL = new URL(url);
      dispatch({
        type: 'FETCH_SUCCESS',
        payload: { ...state.data, url, hostname: newURL.hostname },
      });
    } else {
      dispatch({ type: 'FETCH_FAILURE', payload: { ...state.data, url }, error: 'Invalid format of Odoo URL' });
    }
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    if (validateOdooConnection(state.data)) {
      const creds = { ...state.data };

      if (connectionId) {
        sendRequest(`connections/${connectionId}`, 'PUT', creds)
          .then((response) => {
            if (response.status_code === 200) {
              dispatch({ type: 'FETCH_SUCCESS', payload: response.data, message: response.message });
              // navigate('/connections');
            } else {
              dispatch({ type: 'FETCH_FAILURE', error: response.message || UNKNOWN_ERROR_MESSAGE });
            }
          })
          .catch((err) => {
            dispatch({ type: 'FETCH_FAILURE', error: err.message || UNKNOWN_ERROR_MESSAGE });
          });
      } else {
        sendRequest('connections', 'POST', creds)
          .then((response) => {
            if (response.status_code === 200) {
              dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
              navigate(`/connections/${response.data._id}`);
            } else {
              dispatch({ type: 'FETCH_FAILURE', error: response.message || UNKNOWN_ERROR_MESSAGE });
            }
          })
          .catch((err) => {
            dispatch({ type: 'FETCH_FAILURE', error: err.message || UNKNOWN_ERROR_MESSAGE });
          });
      }
    } else {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please fill all required fields' });
    }
  };

  const handleTestClick = (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    if (validateOdooConnection(state.data) && validateURL(state.data.url)) {
      sendRequest('connections/test', 'POST', state.data)
        .then((response) => {
          if (response.status_code === 200) {
            if (response.data) {
              dispatch({ type: 'FETCH_SUCCESS', message: 'Connection is valid' });
            } else {
              dispatch({ type: 'FETCH_FAILURE', error: 'Connection failed. Please check credentials and try again.' });
            }
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.message || UNKNOWN_ERROR_MESSAGE });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
        });
    } else {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please make sure that all fields are filled and URL is valid' });
    }
  };

  const handleRemoveClick = (e) => {
    e.preventDefault();

    if (window.confirm('Are you sure you want to remove Odoo credentials?')) {
      sendRequest(`connections/${connectionId}`, 'DELETE')
        .then((response) => {
          if (response.status_code === 200) {
            // Redirect to list of connections
            navigate('/connections');
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.message || UNKNOWN_ERROR_MESSAGE });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
        });
    }
  };

  React.useEffect(() => {
    if (connectionId) {
      sendRequest(`connections/${connectionId}`, 'GET')
        .then((response) => {
          if (response.status_code === 200) {
            dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.message || UNKNOWN_ERROR_MESSAGE });
          }
        })
        .catch((err) => {
          dispatch({ type: 'FETCH_FAILURE', error: err.message || UNKNOWN_ERROR_MESSAGE });
        });
    } else {
      dispatch({ type: 'FETCH_SUCCESS' });
    }
  }, [connectionId]);

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="8">
          <Form>
            <Row className="mb-4">
              <h4 className="mb-4">Odoo Connection Details</h4>
              <p>
                To create, edit and publish ZPL labels designer needs to have access
                to limited data in your Odoo instance.
              </p>
              <div>
                <strong>The following credentials are used to connect to your Odoo instance:</strong>
                <ul>
                  <li>URL</li>
                  <li>Database Name</li>
                  <li>ZPL Label Designer API Key</li>
                </ul>
              </div>
              <div>
                <strong>Designer will have access to the following data:</strong>
                <ul>
                  <li>Read models list (ID, Name attributes)</li>
                  <li>Read model fields</li>
                  <li>Create/Update labels</li>
                </ul>
              </div>
            </Row>

            <Row>
              <Form.Group as={Col} className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  value={state.data.name}
                  onChange={handleFieldChange}
                  required
                />
                <Form.Text className="text-muted">
                  You can set any name for your connection.
                </Form.Text>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} className="mb-3" controlId="url">
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="text"
                  name="url"
                  placeholder="Enter Odoo URL"
                  value={state.data.url}
                  onChange={handleURLChange}
                  disabled={connectionId}
                  required
                />
                <Form.Text className="text-muted">
                  Example: https://odoo.domain.com or http://odoo.domain.com:8069
                </Form.Text>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} className="mb-3" controlId="database">
                <Form.Label>Database</Form.Label>
                <Form.Control
                  type="text"
                  name="database"
                  placeholder="Enter Odoo Database"
                  value={state.data.database}
                  onChange={handleFieldChange}
                  disabled={connectionId}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} className="mb-3" controlId="api_key">
                <Form.Label>API Key</Form.Label>
                <Form.Control
                  type="text"
                  name="api_key"
                  placeholder="Enter ZPL Label Designer API Key"
                  value={state.data.api_key}
                  onChange={handleFieldChange}
                  required
                />
              </Form.Group>
            </Row>

            {
              connectionId && (
                <Alert variant="warning">
                  To change URL or Database you need to create a new connection.
                </Alert>
              )
            }

            {
              state.data.deprecated && (
                <Alert variant="warning" className="d-none">
                  <strong className="text-danger">
                    Your version of ZPL Label Designer module is deprecated.
                    Please update module to the latest version.
                    We will remove support for old versions in the future.
                  </strong>
                </Alert>
              )
            }

            {state.isError && (
              <Alert variant="danger">
                {state.error}
              </Alert>
            )}

            {state.message && (
              <Alert variant="success">
                {state.message}
              </Alert>
            )}

            <div className="d-flex justify-content-between">
              <ButtonGroup>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSaveClick}
                  disabled={state.isLoading || !(validateOdooConnection(state.data) && validateURL(state.data.url))}
                >
                  Save
                </Button>
                <Button
                  variant="outline-primary"
                  type="submit"
                  onClick={handleTestClick}
                  disabled={state.isLoading || !(validateOdooConnection(state.data) && validateURL(state.data.url))}
                >
                  Test Connection
                </Button>

                {connectionId && (
                  <Button variant="outline-danger" type="submit" onClick={handleRemoveClick} disabled={state.isLoading}>Remove</Button>
                )}
              </ButtonGroup>

              {state.isLoading && <Spinner animation="border" />}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Connection;
